import GameCard from "../components/gameCard/GameCard";
import Games from "../components/games/Games";
import "../scss/catalog.scss";
import "../scss/news.scss";
import Payform from "../components/payform/Payform";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import EmptyPage from "../components/emptyPage/EmptyPage";
import {EmptyCart} from "../images/backgrounds";
import {useBasketService} from "../services/BasketService";
import CartItem from "../components/cartItem/CartItem";
import {AuthContext} from "../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCart} from "../providers/CartProvider";

const Cart = () => {
    const [empty, setEmpty] = useState(false);
    const {items, setItems, sum, setSum} = useCart(); // Данные суммы и перечень товаров
    const {isAdd, setIsAdd, checkBasket, addItemToBasket, removeItemToBasket, createBasket} = useBasketService();
    const {isAuth} = useContext(AuthContext);
    const navigate = useNavigate();
    const isMounted = useRef(items);
    const {t} = useTranslation();

    //Обновление корзины
    const updateBasket = async () => {
        const basketId = localStorage.getItem('basketId');
        if (basketId) {
            try {
                const updatedBasket = await checkBasket(basketId);
                if (updatedBasket && Array.isArray(updatedBasket.basketItems)) {
                    setItems(updatedBasket.basketItems);
                } else {
                    setItems([]);
                    setEmpty(true);
                }
            } catch (error) {
                console.error('Error updating basket:', error);
                setItems([]);
                setEmpty(true);
            }
        }
    };

    //Обновление корзины при загрузке компонента
    useEffect(() => {
        if (localStorage.getItem('token') == null) navigate('/');
        updateBasket();  // Обновление корзины при загрузке компонента
    }, [isAuth, isAdd]);

    // Редактирование корзины
    const editBasket = async () => {
        const basketId = localStorage.getItem('basketId');

        if (basketId) {
            const editItems = isMounted.current;
            for (const item of editItems) {
                try {
                    if (item.show === false) {
                        await removeItemToBasket(basketId, item.productId, item.count);
                    } else if (!isNaN(item.newCount) && item.newCount !== item.count) {
                        const newCnt = item.newCount;
                        const cnt = item.count;

                        if (cnt > newCnt) {
                            await removeItemToBasket(basketId, item.productId, cnt - newCnt);
                        } else if (newCnt > cnt) {
                            await addItemToBasket(basketId, item.productId, newCnt - cnt);
                        }
                    }
                } catch (error) {
                    console.error(`Ошибка при обновлении элемента корзины: ${item.productId}, error`);
                }
            }
            updateBasket(); // обновление корзины после редактирования
        } else {
            setIsAdd(false);
            navigate('/');
        }
    };

    //Удаление товара
    const removeItem = (productId) => {
        setItems(items => {
            const updatedItems = items.filter(item => item.productId !== productId);
            if (updatedItems.length === 0) {
                setEmpty(true);
            }
            return updatedItems;
        });
    };


    useEffect(() => {
        setSum(0);//Устанавливаем сумму в 0 перед пересчетом
        isMounted.current = items;
        return () => {
            if (isMounted.current) {
                editBasket();
            }
        };
    }, []);

    // Подсчет суммы заказа
    useEffect(() => {
        setSum(items.reduce((acc, item) => {
                const itemPrice = item.price;
                console.log("Cart, Подсчет суммы. Сумма = ", item.price)
                return acc + itemPrice;
            }, 0)
        );

        //Проверка состояния корзины
        if (items.length === 0 || items.filter((item) => item.show !== false).length === 0) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    }, [items]);

    //обновление количества товара
    const setCountItem = (productId, count) => {
        setItems(items =>
                items.map((item) =>
                    item.productId === productId
                        ? {...item, newCount: count, deleted: false}
                        : item
                )
            // if (updatedItems.length === 0 || updatedItems.every(item => item.newCount === 0)) {
            //     setEmpty(true);
            // }
        );
    };

    useEffect(() => {
        if (localStorage.getItem('basketItems')) {
            let localItems = JSON.parse(localStorage.getItem('basketItems'));

            if (!Array.isArray(localItems)) {
                localItems = [];
            }

            const basketId = localStorage.getItem('basketId');
            if (basketId) {
                localItems.forEach((item) => {
                    addItemToBasket(basketId, item.id, item.count)
                        .then()
                        .catch(err => console.log(err));
                });

                checkBasket(basketId)
                    .then(data => {
                        if (data && Array.isArray(data.basketItems)) {
                            setItems(data.basketItems);
                        } else {
                            setItems([]);
                            setEmpty(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setItems([]);
                        setEmpty(true);
                    });
            } else {
                createBasket(localItems.map((item) => ({id: item.id, count: item.count})))
                    .then(data => {
                        localStorage.setItem('basketId', data.id);
                        setIsAdd(true);
                    })
                    .catch(e => {
                        console.log(e);
                        setItems([]);
                        setEmpty(true);
                    });
            }
            localStorage.removeItem('basketItems');
        }
    }, []);

    useEffect(() => {
        if (isAdd) {
            const basketId = localStorage.getItem('basketId');
            if (basketId) {
                checkBasket(basketId)
                    .then(data => {
                        if (data && data.basketItems) {
                            setItems(data.basketItems);
                        } else {
                            setItems([]);
                            setEmpty(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setItems([]);
                        setEmpty(true);
                    });
            }
        }
    }, [isAdd]);

    const children = useMemo(() => (
        items.length > 0 ? items.map((item) => (
            item.show === false ? null : <CartItem
                startCount={item.deleted ? item.newCount : item.count}
                productId={item.productId}
                deleted={(productId) => {
                    const timerId = setTimeout(() => {
                        removeItem(productId);
                        clearTimeout(timerId);
                    }, 5000);
                }}
                key={item.productId}
                setCountItem={setCountItem}/>
        )) : null), [items]);

    const page = (
        !empty ? (
            <>
                <div className="window">
                    <div className="window__row">
                        <div className="window__big">
                            <div className="frame">
                                {children}
                            </div>
                        </div>
                        <div className="window__side">
                            <Payform
                                sum={sum}
                                modal={true}
                                paymentSelected={true} // Указываем, что корзина готова к оплате (можете изменить логику для проверки условий)
                                navigateToPayment={() => navigate('/payment')}
                            />
                        </div>
                    </div>
                </div>

                <Games title={t("new-games")}
                       filter={{pageNumber: 1}}
                       noButton
                       render={(index, name, price, priceWithDiscount, id, imagePath) =>
                           (<GameCard
                               key={index}
                               name={name}
                               price={price}
                               oldPrice={priceWithDiscount}
                               id={id}
                               src={imagePath}
                           />)}>
                </Games>
            </>
        ) : <EmptyPage mainText={t("empty-cart")} text={t("ques-add")} src={EmptyCart}/>
    );

    return (
        <main className="main">
            <div className="main__container">
                <div className="filter">
                    <div className="filter__row">
                        <div className="filter__title title">{t('cart')}</div>
                    </div>
                </div>
                {page}
            </div>
        </main>
    );
};

export default Cart;