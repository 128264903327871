import React, {useState} from "react";
import {EditPencil} from "../../images/system";
import "../../scss/profile.scss";
import Input from "../input/Input";
import {RightLong} from "../../images/arrows";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Payform = (props) => {
    const [promo, setPromo] = useState(false);
    const {sum, paymentSelected, navigateToPayment} = props;
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();

    // Получаем активную валюту из Redux
    const activeCurrency = useSelector(state => state.cart.activeCurrency);

    // Проверяем, если activeCurrency доступен, то используем amount, иначе умножаем на 1
    const currencyAmount = activeCurrency ? activeCurrency.amount : 1;
    const currencySymbol = activeCurrency ? activeCurrency.symbol : '₸';

    // Рассчитываем сумму с учетом курса валюты
    const convertedSum = sum * currencyAmount;

    const field = promo ? (
        <Input
            theme={t("promocode")}
            error={t("promocode-error")}
            check={(err) => err.toUpperCase() === "PROMOCODE64"}
            btn={true}
            src={RightLong}
        />
    ) : (
        <div onClick={() => setPromo(true)} className="payform__link">
            <p>{t("promocode-add")}</p>
            <img src={EditPencil} alt="edit pencil" />
        </div>
    );

    return (
        <div className="payform">
            <div className="payform__info">
                {/* Сумма */}
                <div className="payform__content extra">
                    <div className="payform__text">{t('amount')}</div>
                    <div className="payform__price">
                        <p>{Math.ceil(convertedSum).toLocaleString("ru-RU")}</p>
                        <span>{'₸'}</span>
                    </div>
                </div>

                {/* Сумма товаров */}
                <div className="payform__content">
                    <div className="payform__text">{t('amount-games')}</div>
                    <div className="payform__price">
                        <p>{Math.ceil(convertedSum).toLocaleString("ru-RU")}</p>
                        <span>{'₸'}</span>
                    </div>
                </div>

                {/* У меня есть промокод */}
                <div className="payform__frame">{field}</div>
            </div>

            <div className="payform__content">
                {/* Кнопка "Перейти к оплате" */}
                <Button
                    text={t("to-payment")}
                    extra={"btn-light"}
                    onClick={navigateToPayment} // Вызываем функцию для обработки клика
                    disabled={!paymentSelected}
                />

                {open && (
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        {React.Children.map(props.children, (child) => {
                            return React.cloneElement(child);
                        })}
                    </Modal>
                )}
            </div>
        </div>
    );
};


export default Payform;