import Plus from "../../images/status/plus.svg";
import Decrease from "../../images/status/decrease.svg";
import { useContext, useEffect, useState } from "react";
import Button from "../button/Button";
import { Cart, RefreshBlue } from "../../images/system";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { PopupContext } from "../../providers/PopupProvider";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";
import {addItem} from "../../features/cart/cartSlice";

const BoughtCard = (props) => {
    const [count, setCount] = useState(0);
    const { src, itemId, name, text, oldPrice, price, deleted, time, id, status, startCount, cnt } = props;
    const { isAuth } = useContext(AuthContext);
    const { isShow, setShow } = useContext(PopupContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const statusComp = time ? <section className="path">
        <div className="path__row">
            <div className={"status " + (status == 1 ? "" : status == 2 ? "yellow" : "red")}>
                {status == 1 ? t('payed') : status == 2 ? t('waited') : t('not-payed')}
            </div>
            <div className="path__link">•</div>
            <div className="path__link">{id}</div>
            <div className="path__link">•</div>
            <div className="path__link">{time}</div>
        </div>
    </section> : null;

    useEffect(() => {
        setCount(0);
    }, [cnt])

    useEffect(() => {
        if (!isAuth) setCount(0);
    }, [isAuth])

    useEffect(() => {
        if (isNaN(startCount)) {
            const basketItems = JSON.parse(localStorage.getItem('basketItems'));
            if (basketItems) {
                basketItems.map((item, index) => {
                    if (item["id"] === itemId && item["count"] !== 0) setCount(item["count"])
                })
            }
        }
    }, [itemId])

    useEffect(() => {
        if (!isNaN(startCount)) {
            setCount(startCount);
        }
    }, [startCount])

    useEffect(() => {
        let basketItems = JSON.parse(localStorage.getItem('basketItems'));
        if (basketItems) {
            let add = true;
            let removed = [];
            basketItems.map((item, index) => {
                if (item["id"] === itemId) {
                    if (count === 0) removed.push(index);
                    else item["count"] = count;
                    add = false;
                }
            });
            removed.map(item => { basketItems = basketItems.filter((elem, index) => index !== item) })
            if (add && count !== 0) {
                basketItems.push({ id: itemId, name: text, price: price, count });
            }
            localStorage.setItem('basketItems', JSON.stringify(basketItems));
        } else {
            if (count !== 0 && itemId && text && price && count) {
                localStorage.setItem("basketItems", JSON.stringify([{ id: itemId, name: text, price: price, count }]))
            }
        }
    }, [count])

    const addToCart = () => {
        if (isAuth) {
            setCount(1);
            dispatch(addItem({ productId: itemId, name: text, price: price, count: 1 }));
        } else {
            setShow(true);
        }
    }

    const plusToCart = () => {
        if (isAuth) {
            setCount(count => count + 1);
            dispatch(addItem({ productId: itemId, name: text, price: price, count: 1 })); // Отправляем экшен при увеличении количества
        } else {
            setShow(true);
        }
    }

    const minusToCart = () => {
        if (isAuth) {
            setCount(count => count - (count === 0 ? 0 : 1));
            dispatch(addItem({ productId: itemId, name: text, price: price, count: -1 }));
        }
        else setShow(true);
    }

    const button = count === 0 ?
        <Button text={t('add')} src={Cart} extra={"btn-strong"} onClick={addToCart} /> :
        (<div className="counter">
            <div onClick={minusToCart} className="counter__icon">
                <img src={Decrease} alt="decrease count" />
            </div>
            <div className="counter__text">{count}</div>
            <div onClick={plusToCart} className="counter__icon plus">
                <img src={Plus} alt="increase count" />
            </div>
        </div>);

    return (
        <div className={"bought-card" + (deleted ? " deleted" : "")}>
            <div className="bought-card__img">
                <img src={src} alt="" />
            </div>

            <div className="bought-card__content">
                {statusComp}
                {name ? <div className="bought-card__small">{name}</div> : null}
                <div className="bought-card__text">{text}</div>
                <section className="path">
                    <div className="path__row">
                        {Math.ceil(oldPrice) !== Math.ceil(price) ? <div className="bought-card__text old">{oldPrice === price ? null : Math.ceil(oldPrice).toLocaleString('ru-RU')}</div> : null}
                        <div className="bought-card__text">{price}</div>
                        <div className="path__link">₸</div>
                    </div>
                </section>
            </div>

            <div className="bought-card__btn">
                {button}
            </div>

            <div className="bought-card__deleted">
                <p>{t('cancel')} 5..</p>
                <img src={RefreshBlue} />
            </div>
        </div>
    );
}

export default BoughtCard;