import {useState} from "react";
import Modal from "../components/modal/Modal";
import Payform from "../components/payform/Payform";
import PaymentItem from "../components/paymentItem/PaymentItems";
import {Bank, Kaspi, Visa} from "../images/payment";
import Button from "../components/button/Button";
import useMainService from "../services/MainService";
import {useTranslation} from "react-i18next";
import {useCart} from "../providers/CartProvider";

const Payment = () => {
    const {getKassa24Link} = useMainService();
    const [current, setCurrent] = useState(-1);
    const [errorMessage, setErrorMessage] = useState(''); // состояние для сообщения об ошибке
    const [isModalOpen, setIsModalOpen] = useState(false); // состояние для модального окна
    const {sum} = useCart();
    const {t} = useTranslation();

    const payments = [
        {
            src: Bank,
            title: (
                <span>
                    {t("payment-by-card")}
                    <img
                        src={Visa}
                        alt="Visa"
                        style={{width: "80px", marginLeft: "10px", display: "inline-block", verticalAlign: 'middle'}}/>
                </span>
            ),
            action: async () => {
                let basketId = localStorage.getItem('basketId');
                if (basketId) {
                    return getKassa24Link(basketId)
                        .then(data => {
                            if (data.link_url) {
                                return data.link_url; // Возвращаем ссылку
                            } else {
                                throw new Error(t("payment-text3"));
                            }
                        });
                } else {
                    throw new Error('Не удалось получить идентификатор корзины.');
                }
            }
        },
        // {
        //     src: Kaspi,
        //     title: (
        //         <span>
        //             {"Другой банк"}
        //             <img
        //                 src={Visa}
        //                 alt="Visa"
        //                 style={{width: "80px", marginLeft: "10px", display: "inline-block", verticalAlign: 'middle'}}/>
        //         </span>
        //     ),
        //     action: () => {
        //         console.log("Оплата через другой банк"); // срабатывает
        //     }
        // }
    ];

    const navigateToPayment = async () => {
        if (current === -1) {
            // Если ничего не выбрано, выводим сообщение об ошибке
            setErrorMessage('Выберите способ оплаты, прежде чем продолжить.');
            setIsModalOpen(true);
            return; // Прерываем выполнение, если способ оплаты не выбран
        }

        try {
            const resultLink = await payments[current].action();
            if (resultLink) {
                window.location.href = resultLink; // Редирект на ссылку Kassa24
            } else {
                setErrorMessage('Ссылка на оплату отсутствует. Пожалуйста, попробуйте снова.');
                setIsModalOpen(true); // Открываем модальное окно при ошибке
            }
        } catch (error) {
            setErrorMessage('Ссылка на оплату отсутствует. Пожалуйста, попробуйте снова.');
            setIsModalOpen(true); // Открываем модальное окно при ошибке
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Закрываем модальное окно
        setErrorMessage(''); // Очищаем сообщение об ошибке
    };

    return (
        <main className="main">
            <div className="main__container">
                <div className="filter">
                    <div className="filter__row">
                        <div className="filter__title title">{t('payment')}</div>
                    </div>
                </div>
                <div className="window">
                    <div className="window__row">
                        <div className="window__big">
                            <div className="payments">
                                {payments.map((item, index) => (

                                    <PaymentItem
                                        onClick={() => setCurrent(index)}
                                        key={index}
                                        src={item.src}
                                        title={item.title}
                                        text={item.text}
                                    />

                                ))}
                            </div>
                        </div>
                        <div className="window__side">
                            <Payform
                                sum={sum}
                                paymentSelected={current !== -1}
                                navigateToPayment={navigateToPayment}/>

                            {/* Модальное окно для отображения ошибок */}
                            {isModalOpen && (
                                <Modal open={isModalOpen} onClose={handleCloseModal}>
                                    <div className="modal__content" style={{color: "white"}}>
                                        <h2 style={{color: "red"}}>{t("not-payed")}</h2>
                                        <p>{errorMessage ? errorMessage : 'Произошла ошибка.'}</p>
                                        <Button
                                            text={t("button-close-title")}
                                            extra={"btn-medium"}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Payment;
